/* General styles */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

body {
  background-color: #f6f8fa;
  color: #333;
}

/* Navigation */
nav {
  background-color: #fff;
  padding: 1rem 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

nav ul {
  display: flex;
  list-style-type: none;
}

nav li {
  margin-right: 1rem;
}

/* Content */
.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

p {
  line-height: 1.5;
  margin-bottom: 1rem;
}

/* Home page */
.home {
  text-align: center;
}

.home h1 {
  margin-bottom: 2rem;
}

/* Legal pages */
.legal {
  max-width: 800px;
  margin: 0 auto;
}

.legal h1 {
  margin-bottom: 2rem;
}

.legal-section {
  margin-bottom: 2rem;
}

.legal-section h2 {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

.legal-subsection {
  margin-left: 1rem;
  margin-bottom: 1rem;
}

.legal-subsection h3 {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

body.light {
  background-color: #f6f8fa;
  color: #333;
}

body.dark {
  background-color: #121212;
  color: #f6f8fa;
}

nav.dark {
  background-color: #1f1f1f;
}

nav.dark ul li a {
  color: #f6f8fa;;
}

nav.dark ul li a:hover {
  color: #c5c5c5;
}

nav.dark ul li button {
  color: #f6f8fa;
  background-color: #1f1f1f;
}

/* Add this to your existing App.css */
nav ul li button {
  border: none;
  background-color: transparent;
  cursor: pointer;
  padding: 0;
  font-size: 1rem;
  color: inherit; /* Use the same color as other nav elements */
}

nav ul li button:focus {
  outline: none;
}

nav.dark ul li button {
  color: #f6f8fa;
}

nav.dark ul li button:hover {
  color: #c5c5c5;
}

/* Add this to your existing App.css */
nav {
  background-color: #ffffff;
  transition: background-color 0.3s ease-in-out;
}

nav.dark {
  background-color: #1f1f1f;
}

nav ul {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

nav ul li:last-child {
  margin-left: auto;
}

nav ul li a {
  color: #333; /* Change this to the desired link color */
  text-decoration: none;
}

nav ul li a:hover {
  color: #555; /* Change this to the desired hover color */
}

.dark a {
  color: #f6f8fa;
}

.dark a:hover {
  color: #88c0d0;
}

.logo {
  width: 75px;
  height: auto;
  margin-right: 15px;
}

nav ul {
  display: flex;
  align-items: center;
}

nav li {
  margin-right: 10px;
}

.transparent-logo {
  width: 100%;
  max-width: 300px; /* You can adjust the max-width to your preference */
  height: auto;
}

@media screen and (max-width: flex) {
  .App nav ul {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
  }

  .container {
    padding: 1rem;
  }

  .transparent-logo {
    max-width: 250px; /* Adjust the max-width for mobile devices */
  }
}